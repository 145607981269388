import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthActionTypes, RootState, UserState } from "../../redux/actions/typeAction";
import { Collapse } from "../collapse/collapse";
import logo from "../../assets/icon.png";
import "./header.scss";
import { clearCart, Login, Logout, TokenOff } from "../../redux/actions/action";
import { Dispatch } from "redux";


export function Header() {
	const token = useSelector((state: RootState) => state.token.token);
	const user = useSelector((state: RootState) => state.user);
	const [searchQuery, setSearchQuery] = useState("");
	const navigate = useNavigate();
	const dispatch: Dispatch<AuthActionTypes> = useDispatch();

	const handleSearchSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		if (searchQuery) {
			navigate(`/${searchQuery}`);
		}
	};

	useEffect(() => {
		if (token) {
			fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/profile`, {
				method: "POST",
				headers: {
					Authorization: "Bearer " + token,
				},
			})
				.then((profileResponse) => profileResponse.json())
				.then((profileData) => {
					if (profileData.status === 200) {
						const newUser: UserState = {
							id: profileData.body.id,
							firstName: profileData.body.firstName,
							lastName: profileData.body.lastName,
							email: profileData.body.email,
							adresse: profileData.body.adresse,
							tel: profileData.body.tel,
							created_at: profileData.body.created_at,
						};
						dispatch(Login(newUser));
					}else{
					dispatch(TokenOff());
					dispatch(Logout());
					dispatch<any>(clearCart());
					}
				});
		}
	}, [token, dispatch]);

	return (
		<div className="header">
			<div className="main-logo">
				<NavLink className="main-logo-link" aria-label="go to home" to={"/"}>
					<img
						className="main-logo-img"
						src={logo}
						alt="candyShopByStess Logo"
					/>
				</NavLink>
			</div>
			<div className="navbar">
				<Collapse
					title={"Par occasions"}
					value={[
						{
							text: "Anniverssaire",
							icon: <i className="fa-solid fa-cake-candles"></i>,
							link: "/anniversaire",
						},
						{
							text: "Naissance",
							icon: <i className="fa-solid fa-baby"></i>,
							link: "/naissance",
						},
						{ text: "Mariage", icon: <></>, link: "/mariage" },
						{ text: "Baptême", icon: <></>, link: "/baptême" },
						{ text: "Fête des mères", icon: <></>, link: "/fete-des-meres" },
						{
							text: `Saint valentin`,
							icon: <i className="fa-solid fa-heart"></i>,
							link: "/saint-valentin",
						},
					]}
				/>
				<form className="search-form" onSubmit={handleSearchSubmit}>
					<label htmlFor="search-input" className="visually-hidden">
						Rechercher
					</label>
					<input
						type="text"
						id="search-input"
						name="search-query"
						placeholder="Rechercher"
						className="search"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
					/>
					<button type="submit" className="search-button" aria-label="search">
						<i className="fa-solid fa-magnifying-glass"></i>
					</button>
				</form>
				<div className="connection">
					{!token && (
						<NavLink
							to="/login"
							className="connection-link"
							aria-label="connection"
						>
							<i className="fa-solid fa-user"></i>
							<p>Connexion</p>
						</NavLink>
					)}
					{token && (
						<>
							<NavLink to={"/user"}>
								<p>{user.firstName}</p>
								<i className="fa-solid fa-user"></i>
							</NavLink>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
