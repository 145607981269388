import React from "react";
import { useConfig } from "../../configContext";

export const SelectDeco: React.FC = () => {
  const { setDeco, deco } = useConfig();

  return (
    <div className="deco">
      <h2>Étape 4 : Choisir la décoration</h2>
      <h3>Si vous souhaitez ajouter une décoration à votre produit,<br/> 
      veuillez cocher cette case et nous indiquer en commentaire le thème ou les couleurs souhaitées <br/> 
      (par exemple : anniversaire Disney, Noël, mariage bleu et blanc, ...).</h3>
        <input type="checkbox" key={"deco"} checked={deco.avecDeco} onChange={() => setDeco(!deco.avecDeco)}>
        </input>
        {deco.avecDeco === true && <textarea placeholder="Commentaire" autoCorrect="on" onChange={(e) => setDeco(true, e.target.value)}></textarea>}
    </div>
  );
};
