import React from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import cookiePicture from "../../assets/404.png";
import "./errorPage.scss";

export function ErrorPage() {

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>404 - Page non trouvée</title>
				<meta
					name="description"
					content="Désolé, cette page n'existe pas. Retournez à l'accueil."
				/>
				<link rel="canonical" href="https://candyshop-by-stess.fr/404" />
				<script type="application/ld+json">{JSON.stringify(structuredData)}</script>
			</Helmet>
			<NavLink to="/" className="homePage-link-back" aria-label="Retour à l'accueil">
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="errorPage page">
				<div className="title">
					<h1>4</h1>
					<img src={cookiePicture} alt="cookie" />
					<h2>4</h2>
				</div>
				<h3>Désolé, cette page à été dévorée</h3>
			</div>
		</>
	);
}
