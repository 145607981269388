import React, { useState } from "react";
import { useConfig } from "../../configContext";

export const SelectForm: React.FC = () => {
	const { getFormesDisponibles, setForme } = useConfig();
	const formes = getFormesDisponibles();
    const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  

	return (
		<div className="form">
			<h2>Étape 2 : Choisir une forme</h2>
			{formes.map((forme) => (
				<button
					key={forme}
					className={` ${forme} ${selectedProduct === forme ? "selected" : ""}`}
					onClick={() => {setForme(forme); setSelectedProduct(forme)}}
				></button>
			))}
		</div>
	);
};
