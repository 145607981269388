import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/actions/typeAction";
import "./orders.scss";

interface orderModel {
	id: number;
	created_at: string;
	total_amount: number;
	stripe_transaction_id: string;
	status: string;
	tracking_number: string;
}

export function Commandes() {
	const [userOrders, setUserOrders] = useState([]);
	const token = useSelector((state: RootState) => state.token.token);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/orders`, {
			method: "POST",
			headers: {
				Authorization: "Bearer " + token,
			},
		})
			.then((ordersFetched) => ordersFetched.json())
			.then((ordersDataJson) => {
				if (ordersDataJson.status !== 200) {
					setErrorMessage("Erreur lors de la récupération des commandes.");
				} else {
					setUserOrders(ordersDataJson.body);
				}
			})
			.catch((error) => {
				setErrorMessage("Une erreur est survenue, veuillez réessayer.");
			});
	}, [token]);

	const handleOrderClick = (orderId: number) => {
		navigate(`/historique-commandes/${orderId}`);
	};

	if (errorMessage) {
		return <div>{errorMessage}</div>;
	}

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>Historique de vos commandes - CandyShop by Stess</title>
				<meta
					name="description"
					content="Consultez l'historique complet de vos commandes passées sur CandyShop by Stess, avec tous les détails comme les montants, statuts et numéros de suivi."
				/>
				<meta name="robots" content="noindex, nofollow" />
				<link
					rel="canonical"
					href="https://candyshop-by-stess.fr/historique-commandes"
				/>
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			</Helmet>
			<NavLink
				to={`/user`}
				className="homePage-link-back"
				aria-label="Retour à la page utilisateur"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="ordersPage page">
				<h1>Commandes</h1>
				<table className="ordersTable">
					<thead>
						<tr>
							<th>Date</th>
							<th>Montant</th>
							<th>Status</th>
							<th>N° de transaction</th>
							<th>N° de suivi</th>
						</tr>
					</thead>
					<tbody>
						{userOrders.length === 0 ? (
							<tr>
								<td colSpan={5}>Aucune commande trouvée</td>
							</tr>
						) : (
							userOrders.map((order: orderModel) => (
								<tr
									key={order.id}
									onClick={() => handleOrderClick(order.id)}
									style={{ cursor: "pointer" }}
								>
									<td>
										{order.created_at
											.slice(0, 10)
											.split("-")
											.reverse()
											.join("/")}
									</td>
									<td>{(order.total_amount / 100).toFixed(2)} €</td>
									<td>{order.status}</td>
									<td>{order.stripe_transaction_id}</td>
									<td>{order.tracking_number || "Non renseigné"}</td>
								</tr>
							))
						)}
					</tbody>
				</table>{" "}
			</div>
		</>
	);
}
