import React, { useEffect, useRef, useState } from "react";
import "./select.scss";

interface OptionProps {
	title: string;
	value: number;
}

interface SelectProps {
	title: string;
	groupe1: {
		titile: string;
		options: OptionProps[];
	};
	groupe2: {
		titile: string;
		options: OptionProps[];
	};
	onSelect: (option: OptionProps) => void;
}

export function Select(props: SelectProps) {
	const [isToggled, setToggle] = useState(false);
	const selectRef = useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setToggle(!isToggled);
	};

	const handleSelect = (option?: OptionProps) => {
		setToggle(!isToggled);
		if (option) {
			props.onSelect(option); // Appeler la fonction onSelect passée en prop
			return option;
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			selectRef.current &&
			!selectRef.current.contains(event.target as Node)
		) {
			setToggle(false);
		}
	};

	useEffect(() => {
		// Ajoute un écouteur pour détecter les clics à l'extérieur
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Nettoie l'écouteur pour éviter les fuites de mémoire
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="select" ref={selectRef}>
			<div className="select_title" onClick={handleToggle}>
				{props.title}
				<i
					className={`fa-solid fa-chevron-up ${isToggled ? "" : "rotated"}`}
				></i>
			</div>
			<div className={`select_content ${isToggled ? "show" : "hidden"}`}>
				<ul>
					<span key={props.groupe1.titile} className="title1">
						{props.groupe1.titile}
					</span>
					{props.groupe1.options.map((option, index) => (
						<li key={index} onClick={() => handleSelect(option)}>
							{option.title}
						</li>
					))}
					<span key={props.groupe2.titile} className="title2">
						{props.groupe2.titile}
					</span>
					{props.groupe2.options.map((option, index) => (
						<li key={index} onClick={() => handleSelect(option)}>
							{option.title}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
