import {
	LogoutAction,
	SignInAction,
	TokenOffAction,
	TokenOnAction,
	UserState,
	CartItem,
} from "./typeAction";

export const updateQuantity = (id: number, quantity: number) => ({
  type: "UPDATE_QUANTITY",
  payload: { id, quantity },
});

export const addItem = (item: CartItem) => ({
  type: "ADD_ITEM",
  payload: item,
});

export const removeItem = (id: number) => ({
  type: "REMOVE_ITEM",
  payload: id,
});

export const clearCart = () => ({
  type: "CLEAR_CART",
});

export function Login(data: UserState): SignInAction {
	return {
		type: "SIGN_IN",
		payload: {
			id: data.id,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
      adresse: data.adresse,
			created_at: data.created_at,
      tel: data.tel,
		},
	};
}

export function Logout(): LogoutAction {
	return {
		type: "LOGOUT",
	};
}

export function TokenOn(token: string): TokenOnAction {
	return {
		type: "TOKEN_ON",
		payload: { token },
	};
}

export function TokenOff(): TokenOffAction {
	return {
		type: "TOKEN_OFF",
	};
}
