import React from "react";
import { NavLink } from "react-router-dom";

export function Conditions() {
	return (
		<>
			<NavLink
				to={`/`}
				className="homePage-link-back"
				aria-label="Retour à la page historique des commandes"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="page" style={{ alignItems: "center" }}>
				<h1>Conditions Générales</h1>
				<div style={{ maxWidth: "500px" }}>
					<h2>conditions de Payement</h2>
					<p>
						Méthodes de paiement acceptées : Nous acceptons les paiements par
						carte de crédit (Visa, MasterCard, American Express). Le paiement
						est effectué lors de la commande, et la commande ne sera traitée
						qu'une fois le paiement reçu.
					</p>
					<p>
						Sécurité des paiements : Toutes les transactions sont traitées de
						manière sécurisée grâce à notre technologie de chiffrement SSL,
						garantissant la confidentialité de vos informations personnelles et
						bancaires.
					</p>
				</div>
				<div style={{ maxWidth: "500px" }}>
					<h2>conditions de Retour</h2>
					<p>
						En raison de la nature périssable de nos produits alimentaires, nous
						n'acceptons pas de retours. Cependant, si nous avons commis une
						erreur dans votre commande, nous vous prions de nous contacter dans
						les 48 heures suivant la réception de la commande pour organiser un
						échange ou un remboursement. Exceptions : Si un produit est
						endommagé lors de la livraison, veuillez nous contacter
						immédiatement pour organiser un remplacement ou un remboursement.
					</p>
				</div>
			</div>
		</>
	);
}
