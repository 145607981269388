import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Cart } from "../../components/cart/cart";
import { Select } from "../../components/select/select";
import "./productPage.scss";

interface ProductSchema {
	id: number;
	name: string;
	description: string;
	price: number;
	image_path: string;
	tags: string[];
	poids: number;
}

export function Product() {
	const params = useParams();
	const id = params.id;
	const category = params.category;
	const [product, setProduct] = useState<ProductSchema[]>([]);
	const currentProduct = product[0];
	const [selectedSucre, setSelectedSucre] = useState<string>("");
	const [selectedPrepa, setSelectedPrepa] = useState<string>("");
	const [idOption, setIdOption] = useState<number>(0);
	const [error, setError] = useState<string | null>(null);
	const [rateCost, setRateCost] = useState<number>(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const shippingRates = [
		{ maxWeight: 0.25, cost: 5.25 },
		{ maxWeight: 0.5, cost: 7.35 },
		{ maxWeight: 0.75, cost: 8.65 },
		{ maxWeight: 1, cost: 9.4 },
		{ maxWeight: 2, cost: 10.7 },
		{ maxWeight: 5, cost: 16.6 },
		{ maxWeight: 10, cost: 24.2 },
		{ maxWeight: 15, cost: 30.55 },
		{ maxWeight: 30, cost: 37.85 },
	];

	useEffect(() => {
		const rate = shippingRates.find(
			(rate) => currentProduct?.poids <= rate.maxWeight
		) || {
			cost: 0,
		};
		setRateCost(rate.cost);
	}, [currentProduct]);

	const handleSucreChange = (option: { value: number; title: string }) => {
		setSelectedSucre(option.title);
		setIdOption(option.value * 1000);
		setError(null); // Réinitialiser l'erreur à chaque requête
	};
	const handlePrepaChange = (option: { value: number; title: string }) => {
		setSelectedPrepa(option.title);
		setIdOption(option.value * 1000);
		setError(null); // Réinitialiser l'erreur à chaque requête
	};

	useEffect(() => {
		setError(null); // Réinitialiser l'erreur à chaque requête

		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/product/${id}`)
			.then((response) => {
				if (response.status !== 200) {
					navigate("/404");
				}
				return response.json();
			})
			.then((data) => {
				setProduct(data);
			})
			.catch((err) => {
				setError(err.message);
			});
	}, [id]);

	const addToCart = () => {
		dispatch({
			type: "ADD_ITEM",
			payload: {
				id: product[0]?.id,
				name: product[0]?.name,
				price: product[0]?.price,
				quantity: 1,
				image_path: product[0]?.image_path,
				poids: product[0]?.poids,
			},
		});
	};

	const addToCartSucre = () => {
		if (idOption === 0) {
			setError("Veuillez choisir un arôme.");
			return;
		}
		dispatch({
			type: "ADD_ITEM",
			payload: {
				id: product[0]?.id * idOption,
				name: `${product[0]?.name} arôme ${selectedSucre}`,
				price: product[0]?.price,
				quantity: 1,
				image_path: product[0]?.image_path,
				poids: product[0]?.poids,
			},
		});
	};
	const addToCartPrepa = () => {
		if (idOption === 0) {
			setError("Veuillez choisir un arôme.");
			return;
		}
		dispatch({
			type: "ADD_ITEM",
			payload: {
				id: product[0]?.id * idOption,
				name: `${product[0]?.name} kit ${selectedPrepa}`,
				price: product[0]?.price,
				quantity: 1,
				image_path: product[0]?.image_path,
				poids: product[0]?.poids,
			},
		});
	};

	// Remplir les données structurées pour la liste de produits
	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	const itemList = {
		"@context": "https://schema.org",
		"@type": "Product",
		name: currentProduct?.name,
		description: currentProduct?.description,
		image: `${process.env.REACT_APP_API_URL}/api-candyshop/${currentProduct?.image_path}`,
		offers: {
			"@type": "Offer",
			priceCurrency: "EUR",
			price: currentProduct?.price.toFixed(2),
			availability: "https://schema.org/InStock",
			priceValidUntil: "2025-12-31",
			shippingDetails: {
				"@type": "OfferShippingDetails",
				shippingRate: {
					"@type": "MonetaryAmount",
					value: rateCost.toFixed(2),
					currency: "EUR",
				},
				shippingDestination: {
					"@type": "DefinedRegion",
					addressCountry: "FR",
				},
				shippingWeight: {
					"@type": "QuantitativeValue",
					value: currentProduct?.poids,
					unitText: "kg",
				},
			},
			hasMerchantReturnPolicy: {
				"@type": "MerchantReturnPolicy",
				returnPolicyCategory: "https://schema.org/NoReturns",
				merchantReturnLink: "https://candyshop-by-stess.fr/conditions",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
		review: [
			{
				"@type": "Review",
				author: { "@type": "Person", name: "Sandrine Leblond" },
				datePublished: "2024-11-21",
				reviewBody:
					"Des créations absolument délicieuses, je recommande vivement !",
				reviewRating: {
					"@type": "Rating",
					bestRating: "5",
					worstRating: "1",
					ratingValue: "5",
				},
			},
		],
		keywords: Array.isArray(currentProduct?.tags)
			? currentProduct.tags.join(", ")
			: "",
		url: `https://candyshop-by-stess.fr/${category}/${id}`,
	};

	if (error) {
		return <div className="error">Une erreur est survenue : {error}</div>;
	}
	if (!currentProduct) {
		return <div>Chargement des informations du produit...</div>;
	}

	return (
		<>
			<Helmet>
				<title>CandyShop By Stess {product[0].name}</title>
				<link
					rel="canonical"
					href={`https://candyshop-by-stess.fr/${category}/${id}`}
				/>
				<meta name="description" content={product[0].description} />
				{/* JSON-LD pour les moteurs de recherche */}
				<script type="application/ld+json">
					{JSON.stringify([structuredData, itemList])}
				</script>
			</Helmet>

			<NavLink
				to={`/${category}`}
				className="homePage-link-back"
				aria-label="Retour à la page catégories"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="productPage page">
				<Cart />
				<h1 className="productPage-title">{product[0].name}</h1>
				<div className="productPage-wrapper">
					<div className="productPage-wrapper-img">
						<img
							className="productPage-img"
							src={`${process.env.REACT_APP_API_URL}/api-candyshop/${product[0].image_path}`}
							alt={product[0].name}
						/>
					</div>
					<div className="productPage-wrapper-descripAndPrice">
						<div className="productPage-description">
							<h2>Description du produit</h2>
							<p
								dangerouslySetInnerHTML={{ __html: product[0].description }}
							></p>
						</div>
						<div className="productPage-price">
							<div className="productPage-price-infos">
								<h3>Prix</h3>
								<p>{product[0].price.toFixed(2)} €</p>
							</div>
							{category === "sucre" && (
								<div className="productPage-price-select">
									<label htmlFor="sucre">Choisir un produit</label>
									<Select
										title={selectedSucre || "Choisir un arôme"}
										groupe1={{
											titile: "Classic",
											options: [
												{ title: "vanille", value: 1 },
												{ title: "pomme d'amour", value: 2 },
												{ title: "chocolat & noisette", value: 3 },
												{ title: "fraise", value: 4 },
												{ title: "noix de coco", value: 5 },
												{ title: "framboise", value: 6 },
												{ title: "citron", value: 7 },
												{ title: "letchee", value: 8 },
											],
										}}
										groupe2={{
											titile: "DeLuxe",
											options: [
												{ title: "tarte a la framboise", value: 9 },
												{ title: "pistache", value: 10 },
												{ title: "praline rose", value: 11 },
												{ title: "foret noire", value: 12 },
												{ title: "banane", value: 13 },
											],
										}}
										onSelect={handleSucreChange}
									/>
								</div>
							)}
							{category === "preparations" && (
								<div className="productPage-price-select">
									<label htmlFor="preparations">Choisir un produit</label>
									<Select
										title={selectedPrepa || "Choisir un kit"}
										groupe1={{
											titile: "",
											options: [
												{ title: "chocolat blanc/ praline rose", value: 1 },
												{ title: "chocolat au lait/ guimauves", value: 2 },
												{ title: "spéculoos/ caramel", value: 3 },
												{ title: "noix de coco/ pépites chocolat", value: 4 },
												{ title: "pomme d'amour/ chocolat blanc", value: 5 },
												{ title: "pomme d'amour/ chocolat au lait", value: 6 },
												{ title: "caramel beurre salé", value: 7 },
												{ title: "caramel/ pépites chocolat", value: 8 },
											],
										}}
										groupe2={{
											titile: "",
											options: [
												{ title: "", value: 0 },
												{ title: "", value: 0 },
												{ title: "", value: 0 },
												{ title: "", value: 0 },
												{ title: "", value: 0 },
											],
										}}
										onSelect={handlePrepaChange}
									/>
								</div>
							)}
							<div className="productPage-price-action">
								{category === "sucre" && (
									<button onClick={addToCartSucre}>Ajouter au panier</button>
								)}
								{category === "preparations" && (
									<button onClick={addToCartPrepa}>Ajouter au panier</button>
								)}

								{category !== "sucre" && category !== "preparations" && (
									<button onClick={addToCart}>Ajouter au panier</button>
								)}
							</div>
						</div>
						{error && <p className="error">{error}</p>}
					</div>
				</div>
			</div>
		</>
	);
}
