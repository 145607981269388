import React, { createContext, useContext, useState } from "react";

type ProduitType = "cône" | "brochette" | "sachet" | "cup";

const priceConfig = {
	produits: {
		cône: 4.0,
		brochette: 2.0,
		sachet: 2.5,
		cup: 4.0,
	},
	categories: {
		kinder: 1.0,
		chocolat: 1.0,
		bonbons: 0.0,
		popCorn: -0.5,
	},
	deco: 0.5, // Prix fixe pour la déco
};

// Données globales des options possibles
const configuratorData = {
	produits: ["cône", "brochette", "sachet", "cup"],
	formes: {
		cône: ["cône"],
		brochette: ["brochette"],
		sachet: ["cone"],
		cup: ["cup"],
	},
	categories: {
		cône: ["kinder", "chocolat", "bonbons"],
		brochette: ["bonbons"],
		sachet: ["popCorn", "chocolat", "bonbons"],
		cup: ["kinder", "bonbons"],
	},
	kinder: ["assortiment de kinder aléatoire"],
	popCorn: ["pop-corn et Smarties"],
	chocolats: {
		cône: [
			"boules chocolat au lait et blanc",
			"boules chocolat au lait et fraise",
			"mini-shamallow",
			"nounours guimauve",
		],
		brochette: [
			"boules chocolat au lait et blanc",
			"boules chocolat au lait et fraise",
			"mini-shamallow",
			"nounours guimauve",
		],
		sachet: [
			"boules chocolat au lait et blanc",
			"boules chocolat au lait et fraise",
			"mini-shamallow",
			"nounours guimauve",
		],
		cup: [
			"boules chocolat au lait et blanc",
			"boules chocolat au lait et fraise",
			"mini-shamallow",
			"nounours guimauve",
		],
	},
	bonbons: {
		cône: [
			"Fraise tagada",
			"Fraise pik",
			"Banane",
			"Gros dragibus",
			"Coca classique",
			"Coca acidulé ",
			"Schtroumpfs classique",
			"schtroumpfs acidulé",
			"Cerise",
			"Nounours",
			"Crocodiles",
			"Crocodile acidulé",
			"Rainbow pile",
			"Œuf",
			"Haribo miami pik",
			"Pastèque",
		],
		brochette: [
			"Framboise",
			"Vanille",
			"Pomme",
			"Fraise",
			"Banane",
			"Rose",
			"Violette",
			"Noire",
			"Orange",
		],
		sachet: [
			"Fraise tagada",
			"Fraise pik",
			"Banane",
			"Gros dragibus",
			"Coca classique",
			"Coca acidulé ",
			"Schtroumpfs classique",
			"schtroumpfs acidulé",
			"Cerise",
			"Nounours",
			"Crocodiles",
			"Crocodile acidulé",
			"Rainbow pile",
			"Œuf",
			"Haribo miami pik",
			"Pastèque",
		],
		cup: [
			"Fraise tagada",
			"Fraise pik",
			"Banane",
			"Gros dragibus",
			"Coca classique",
			"Coca acidulé ",
			"Schtroumpfs classique",
			"schtroumpfs acidulé",
			"Cerise",
			"Nounours",
			"Crocodiles",
			"Crocodile acidulé",
			"Rainbow pile",
			"Œuf",
			"Haribo miami pik",
			"Pastèque",
		],
	},
} as const;

// Interface de l'état du configurateur
interface ConfiguratorState {
	produit: string | null;
	forme: string | null;
	category: string | undefined;
	kinder: string[];
	chocolats: string[];
	bonbons: string[];
	deco: {
		avecDeco: boolean;
		commentaire?: string;
	};
}

// Interface des fonctions du context
interface ConfigContextType extends ConfiguratorState {
	setProduit: React.Dispatch<React.SetStateAction<ProduitType | null>>;
	setForme: (forme: string) => void;
	setCategory: (
		category: keyof typeof priceConfig.categories | undefined
	) => void;
	addBonbon: (bonbon: string) => void;
	setDeco: (avecDeco: boolean, commentaire?: string) => void;
	getFormesDisponibles: () => ReadonlyArray<string>;
	getCategoriesDisponibles: () => ReadonlyArray<
		"kinder" | "popCorn" | "chocolat" | "bonbons"
	>;
	getBonbonsDisponibles: () => ReadonlyArray<string>;
	getChocolatsDisponibles: () => ReadonlyArray<string>;
	clearBonbons: () => void;
	clearCategory: () => void;
	getKinder: () => ReadonlyArray<string>;
	getPopCorn: () => ReadonlyArray<string>;
	getPrice: () => number;
	getPoids: () => number;
}

// Création du contexte
const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

// Hook pour utiliser le contexte
export const useConfig = () => {
	const context = useContext(ConfigContext);
	if (!context) {
		throw new Error("useConfig doit être utilisé dans un ConfigProvider");
	}
	return context;
};

// Provider pour le configurateur
export const ConfigProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [produit, setProduit] = useState<ProduitType | null>(null);
	const [forme, setForme] = useState<string>("");
	const [category, setCategory] = useState<
		keyof typeof priceConfig.categories | undefined
	>();
	const [bonbons, setBonbons] = useState<string[]>([]);
	const [kinder] = useState<string[]>([]);
	const [chocolats] = useState<string[]>([]);
	const [deco, setDecoState] = useState<{
		avecDeco: boolean;
		commentaire?: string;
	}>({
		avecDeco: false,
	});

	const clearBonbons = () => setBonbons([]);
	const clearCategory = () => setCategory(undefined);
	// Fonctions utilitaires
	const addBonbon = (bonbon: string) => {
		setBonbons((prev) => {
			if (prev.includes(bonbon)) {
				return prev.filter((item) => item !== bonbon);
			} else {
				if (prev.length < 3) {
					return [...prev, bonbon];
				} else {
					return prev;
				}
			}
		});
	};
	const setDeco = (avecDeco: boolean, commentaire?: string) =>
		setDecoState({ avecDeco, commentaire });
	const getFormesDisponibles = () =>
		produit ? configuratorData.formes[produit] || [] : [];

	const getCategoriesDisponibles = () => {
		return produit
			? (configuratorData.categories[produit] as ReadonlyArray<
					"kinder" | "popCorn" | "chocolat" | "bonbons"
			  >)
			: [];
	};

	const getBonbonsDisponibles = () =>
		produit ? configuratorData.bonbons[produit] || [] : [];

	const getChocolatsDisponibles = () =>
		produit ? configuratorData.chocolats[produit] || [] : [];

	const getKinder = () => (produit ? configuratorData.kinder || [] : []);
	const getPopCorn = () => (produit ? configuratorData.popCorn || [] : []);

	const calculatePrice = () => {
		let total = 0.0;

		// Ajouter le prix du produit
		if (produit) total += priceConfig.produits[produit] || 0;

		// Ajouter le prix de la catégorie
		if (produit === "sachet" && category === "chocolat")
			total += priceConfig.categories[category] - 0.5 || 0;
		// if (produit !== "sachet" && category !== "chocolat" && category !== undefined) total += priceConfig.categories[category] || 0;
		else if (category) total += priceConfig.categories[category] || 0;

		// Ajouter le prix de la déco si déco activée
		if (deco.avecDeco) total += priceConfig.deco;

		return total;
	};

	const calculatePoids = () => {
		let poids = 0.0;

		// Ajouter le poids du produit
		if (produit === "cup") {
			poids += 0.2;
		} else if (produit === "sachet" && category === "popCorn") {
			poids += 0.1;
		} else if (produit === "sachet" && category !== "popCorn") {
			poids += 0.2;
		} else if (produit === "brochette") {
			poids += 0.1;
		} else if (produit === "cône") {
			poids += 0.2;
		}

		return poids;
	};

	return (
		<ConfigContext.Provider
			value={{
				produit,
				forme,
				category,
				kinder,
				chocolats,
				bonbons,
				deco,
				setProduit,
				setForme,
				setCategory,
				addBonbon,
				setDeco,
				getFormesDisponibles,
				getCategoriesDisponibles,
				getKinder,
				getPopCorn,
				getBonbonsDisponibles,
				getChocolatsDisponibles,
				clearBonbons,
				clearCategory,
				getPrice: calculatePrice,
				getPoids: calculatePoids,
			}}
		>
			{children}
		</ConfigContext.Provider>
	);
};
