import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Cart } from "../../components/cart/cart";
import { useConfig } from "../../configContext";
import { SelectCandies } from "./SelectCandies";
import { SelectDeco } from "./SelectDeco";
import { SelectForm } from "./SelectForm";
import { SelectProduct } from "./SelectProduct";

import { Helmet } from "react-helmet";
import "./configurator.scss";

export const Configurator: React.FC = () => {
	const [step, setStep] = useState(1);
	const {
		produit,
		forme,
		bonbons,
		category,
		deco,
		setForme,
		setDeco,
		setProduit,
		clearBonbons,
		clearCategory,
		getPrice,
		getPoids,
	} = useConfig();

	const dispatch = useDispatch();
	const dateDay = new Date().toLocaleDateString();
	const dateTime = new Date().toLocaleTimeString();
	const id = Number(dateDay.replace(/\D/g, "") + dateTime.replace(/\D/g, ""));

	const resetConfig = () => {
		setStep(1);
		setForme("");
		setDeco(false, "");
		clearBonbons();
		clearCategory();
		setProduit(null);

	};

	const addToCart = (prod: string) => {
		dispatch({
			type: "ADD_ITEM",
			payload: {
				id: id,
				name: prod,
				price: getPrice(),
				quantity: 1,
				image_path: `assets/${produit}-${forme}-${category}.png`,
				poids: getPoids(),
			},
		});
		resetConfig();
	};

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>Configurateur de Produits | Candyshop By Stess</title>
				<meta
					name="description"
					content="Configurez votre produit personnalisé : choisissez la forme, les bonbons, et la décoration de votre création sucrée."
				/>
				<link
					rel="canonical"
					href="https://candyshop-by-stess.fr/configurator"
				/>
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			</Helmet>
			<NavLink
				to="/"
				className="homePage-link-back"
				onClick={() => {
					resetConfig();
				}}
				aria-label="Retour à l'accueil"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="configurator-container page">
				<Cart />
				<div className="config-wrapper">
					<h1>Configurateur de Produits</h1>
					<div className="configurator-recap">
						<div>
							<h2>produit :</h2>
							<h4>{produit}</h4>
						</div>
						<div>
							<h2>forme :</h2>
							<h4>{forme}</h4>
						</div>
						<div>
							<h2>bonbons :</h2>
							<h4>{bonbons.join(", ")}</h4>
						</div>
						<div>
							<h2>avec déco :</h2>
							<h4>{deco.avecDeco === true ? "oui" : "non"}</h4>
						</div>
						<div>
							<h2>commentaire :</h2>
							<h4>{deco.commentaire}</h4>
						</div>
					</div>
					<div className="configurator-wrapper">
						{step >= 3 && (
							<div className="display-selection">
								<h2>exemple</h2>
								{category && (
									<img
										className="display-selection-img"
										src={`/assets/${produit}-${forme}-${category}.png`}
										alt="exemple du produit"
									/>
								)}
								<h3>{getPrice().toFixed(2)} €</h3>
							</div>
						)}
						{step === 1 && <SelectProduct />}
						{step === 2 && <SelectForm />}
						{step === 3 && <SelectCandies />}
						{step === 4 && <SelectDeco />}
					</div>

					<div className="configurator-btn">
						{step > 1 && step === 4 && (
							<button
								onClick={() => {
									setStep(step - 1);
									setDeco(false, "");
									clearBonbons();
								}}
								aria-label="Passer à l'étape précedente"
							>
								Précédent
							</button>
						)}
						{step > 1 && step === 3 && (
							<button
								onClick={() => {
									setStep(step - 1);
									clearBonbons();
									clearCategory();
									setForme("");
								}}
								aria-label="Passer à l'étape précedente"
							>
								Précédent
							</button>
						)}
						{step > 1 && step === 2 && (
							<button
								onClick={() => {
									setStep(step - 1);
									setForme("");
									setProduit(null);
								}}
								aria-label="Passer à l'étape précedente"
							>
								Précédent
							</button>
						)}
						{step < 4 && step === 1 && produit !== null && (
							<button
								onClick={() => setStep(step + 1)}
								aria-label="Passer à l'étape suivante"
							>
								Suivant
							</button>
						)}
						{step < 4 && step === 2 && forme !== "" && (
							<button
								onClick={() => setStep(step + 1)}
								aria-label="Passer à l'étape suivante"
							>
								Suivant
							</button>
						)}
						{step < 4 && step === 3 && bonbons.length > 0 && (
							<button
								onClick={() => setStep(step + 1)}
								aria-label="Passer à l'étape suivante"
							>
								Suivant
							</button>
						)}
						{step === 4 && deco.avecDeco === true && (
							<button
								onClick={() =>
									addToCart(
										`${produit}-${forme}-${category}-${bonbons.join(
											"-"
										)}-avec déco-${deco.commentaire}`
									)
								}
								aria-label="Ajouter au panier"
							>
								ajouter au panier
							</button>
						)}
						{step === 4 && deco.avecDeco === false && (
							<button
								onClick={() =>
									addToCart(
										`${produit}-${forme}-${category}-${bonbons.join(
											"-"
										)}-sans déco`
									)
								}
								aria-label="Ajouter au panier"
							>
								ajouter au panier
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
