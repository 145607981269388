import React, { useEffect } from "react";

// import "./tarteaucitron.scss";

export function CookieConsent() {
	useEffect(() => {
		const checkTarteaucitron = setInterval(() => {
			if (window.tarteaucitron) {
				clearInterval(checkTarteaucitron); // Arrête l'intervalle une fois que tarteaucitron est disponible

				// Initialisation de Tarteaucitron
				window.tarteaucitron.init({
					privacyUrl: "/politique-de-confidentialite", // Lien vers la politique de confidentialité
					hashtag: "#tarteaucitron", // Hashtag pour la politique
					cookieName: "cookieConsent", // Nom du cookie
					orientation: "bottom", // Orientation de la bannière
					showAlertSmall: true, // Afficher une petite alerte
					cookieslist: true, // Afficher la liste des cookies
					closePopup: true, // Fermer le popup
					showIcon: true, // Afficher l'icône
					iconPosition: "BottomRight", // Position de l'icône
				});

				// Ajouter les services
				window.tarteaucitron.services = window.tarteaucitron.services || {};

				// Service Google Analytics
				window.tarteaucitron.services.googleanalytics = {
					key: "googleanalytics",
					type: "analytic",
					name: "Google Analytics",
					uri: "https://policies.google.com/privacy", // Lien vers la politique de confidentialité de Google Analytics
					needConsent: true,
					cookies: ["_ga", "_gid", "_gat"], // Cookies associés à Google Analytics
					js: function () {
						// Ajouter le premier script pour charger gtag.js
						window.tarteaucitron.addScript(
							"https://www.googletagmanager.com/gtag/js?id=G-7RFM0H7GC4",
							"",
							function () {
								// Ajouter le deuxième script (code d'initialisation de Google Analytics)
								const secondScript = document.createElement("script");
								secondScript.type = "text/javascript";
								secondScript.text = `
									window.dataLayer = window.dataLayer || [];
									function gtag(){dataLayer.push(arguments);}
									gtag('js', new Date());
									gtag('config', 'G-7RFM0H7GC4', { anonymize_ip: true });
								`;
								document.head.appendChild(secondScript); // Ajouter le deuxième script dans le <head>
							}						);
					},
				};

				// Service Stripe
				window.tarteaucitron.services.stripe = {
					key: "stripe",
					type: "other", // Type du service
					name: "Stripe (obligatoire pour valider le payement)",
					uri: "https://stripe.com/privacy", // Politique de confidentialité
					needConsent: true, // Nécessite le consentement utilisateur
					cookies: [
						"__Host-session",
						"private_machine_identifier",
						"cookie-perms",
					], // Cookies associés à Stripe
					js: function () {},
				};

				// Ajouter les services au tableau des jobs (une seule fois)
				window.tarteaucitron.job = window.tarteaucitron.job || [];
				if (!window.tarteaucitron.job.includes("googleanalytics")) {
					window.tarteaucitron.job.push("googleanalytics");
				}
				if (!window.tarteaucitron.job.includes("stripe")) {
					window.tarteaucitron.job.push("stripe");
				}
			}
		}, 100); // Vérifie toutes les 100 ms si `window.tarteaucitron` est disponible

		return () => clearInterval(checkTarteaucitron); // Nettoie l'intervalle
	}, []);

	return null;
}
