import React, { useEffect, useRef, useState } from "react";
import "./collapse.scss";
import { useNavigate } from "react-router-dom";

interface CollapseProps {
	title: string;
	value: Array<{
		text?: string;
		icon: JSX.Element;
		link?: string;
	}>;
}

export function Collapse(props: CollapseProps) {
	const [isToggled, setToggle] = useState(false);
	const collapseRef = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();

	const handleToggle = () => {
		setToggle(!isToggled);
	};

	const handleNavigate = (link?: string) => {
		setToggle(!isToggled);
		if (link) {
			navigate(link); // Navigue uniquement si `link` est défini
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (
			collapseRef.current &&
			!collapseRef.current.contains(event.target as Node)
		) {
			setToggle(false);
		}
	};

	useEffect(() => {
		// Ajoute un écouteur pour détecter les clics à l'extérieur
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Nettoie l'écouteur pour éviter les fuites de mémoire
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<div className="collapse" ref={collapseRef}>
			<div className="collapse_title" onClick={handleToggle}>
				{props.title}
				<i
					className={`fa-solid fa-chevron-up ${isToggled ? "" : "rotated"}`}
				></i>
			</div>
			<div className={`collapse_content ${isToggled ? "show" : "hidden"}`}>
				<ul>
					{props.value.map((value, index) => (
						<li key={index} onClick={() => handleNavigate(value.link)}>
							{value.text} {value.icon}
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
