import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import configurateurImage425 from "../../assets/configurateur-425.png";
import configurateurImage768 from "../../assets/configurateur-768.png";
import configurateurImage1024 from "../../assets/configurateur-1024.png";
import configurateurImage1440 from "../../assets/configurateur-1440.png";
import { Cart } from "../../components/cart/cart";
import "./categoriesPage.scss";

interface Product {
	id: number;
	name: string;
	description: string;
	price: number;
	image_path: string;
	tags: string[];
	poids: number;
}

export function CategoriesPage() {
	const [products, setProducts] = useState<Product[]>([]);
	const category = useParams();
	const navigate = useNavigate();
	const width = window.innerWidth;

	const shippingRates = [
		{ maxWeight: 0.25, cost: 5.25 },
		{ maxWeight: 0.5, cost: 7.35 },
		{ maxWeight: 0.75, cost: 8.65 },
		{ maxWeight: 1, cost: 9.4 },
		{ maxWeight: 2, cost: 10.7 },
		{ maxWeight: 5, cost: 16.6 },
		{ maxWeight: 10, cost: 24.2 },
		{ maxWeight: 15, cost: 30.55 },
		{ maxWeight: 30, cost: 37.85 },
	];

	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_URL}/api-candyshop/products/${category.category}`
		)
			.then((response) => {
				if (response.status === 404) {
					navigate("/404");
				}
				return response.json();
			})
			.then((data) => setProducts(data));
	}, [category.category, navigate]);

	// Fonction pour vérifier l'orientation de l'image
	useEffect(() => {
		if (category.category === "assortiment") {
			setProducts(products.reverse());
		}
	}, [products, category.category]);

	if (products.length === 0) {
		return <div className="page">Chargement des produits...</div>;
	}

	// Remplir les données structurées pour la liste de produits
	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	const itemList = {
		"@context": "https://schema.org",
		"@type": "ItemList",
		name: category.category, // Nom de la catégorie
		description: `Découvrez notre gamme de ${category.category} chez Candyshop By Stess.`,
		url: `https://candyshop-by-stess.fr/${category.category}`, // URL dynamique pour la catégorie
		itemListElement: products.map((product, index) => ({
			"@type": "ListItem",
			position: index + 1,
			item: {
				"@type": "Product",
				name: product.name,
				description: product.description,
				image: `${process.env.REACT_APP_API_URL}/api-candyshop/${product.image_path}`, // URL complète pour l'image
				url: `https://candyshop-by-stess.fr/${category.category}/${product.id}`,
				offers: {
					"@type": "Offer",
					priceCurrency: "EUR",
					availability: "https://schema.org/InStock",
					price: product.price.toFixed(2), // Format monétaire standard
					priceValidUntil: "2025-12-31", // Date d'expiration du prix
					shippingDetails: {
						"@type": "OfferShippingDetails",
						shippingRate: {
							"@type": "MonetaryAmount",
							value: shippingRates
								.find((rate) => product.poids <= rate.maxWeight)
								?.cost.toFixed(2),
							currency: "EUR",
						},
						shippingDestination: {
							"@type": "DefinedRegion",
							addressCountry: "FR",
						},
						shippingWeight: {
							"@type": "QuantitativeValue",
							value: product.poids,
							unitText: "kg",
						},
					},
					hasMerchantReturnPolicy: {
						"@type": "MerchantReturnPolicy",
						returnPolicyCategory: "https://schema.org/NoReturns",
						merchantReturnLink: "https://candyshop-by-stess.fr/conditions",
					},
				},
				aggregateRating: {
					"@type": "AggregateRating",
					ratingValue: "4.9",
					reviewCount: "85",
				},
				review: [
					{
						"@type": "Review",
						author: { "@type": "Person", name: "Sandrine Leblond" },
						datePublished: "2024-11-21",
						reviewBody:
							"Des créations absolument délicieuses, je recommande vivement !",
						reviewRating: {
							"@type": "Rating",
							bestRating: "5",
							worstRating: "1",
							ratingValue: "5",
						},
					},
				],
				keywords: Array.isArray(product.tags) ? product.tags.join(", ") : "",
			},
		})),
	};

	return (
		<>
			<Helmet>
				<title>{`${category.category} - Candyshop By Stess`}</title>
				<link
					rel="canonical"
					href={`https://candyshop-by-stess.fr/${category.category}`}
				/>
				<meta
					name="description"
					content={`Découvrez notre gamme de ${category.category} chez Candyshop By Stess.`}
				/>
				{/* JSON-LD pour les moteurs de recherche */}
				<script type="application/ld+json">
					{JSON.stringify([structuredData, itemList])}
				</script>
			</Helmet>

			<NavLink
				to="/"
				className="homePage-link-back"
				aria-label="Retour à l'accueil"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="category page">
				<Cart />
				<h1>CandyShop By Stess: Goblets garnis de bonbons ou de Kinder</h1>
				<div className="category-wrapper">
					{category.category !== "assortiment" &&
						category.category !== "gateau" &&
						category.category !== "chocolats-Page" &&
						category.category !== "sucre" &&
						category.category !== "preparations" && (
							<div className={`category-card`}>
								<NavLink
									to="/configurateur"
									className="homePage-wrapper-link brochette-homePage"
									aria-labelledby="configurateur-title"
								>
									<img
										src={
											width <= 425
												? configurateurImage425
												: width > 425 && width <= 768
												? configurateurImage768
												: width > 768 && width <= 1024
												? configurateurImage1024
												: configurateurImage1440
										}
										alt="CandyShop By Stess: configurateur personalisation produits."
									/>
									<h3>Créez vos produits personnalisés</h3>
								</NavLink>
							</div>
						)}
					{products.map((product) => {
						return (
							<div key={product.id} className={`category-card`}>
								<NavLink to={`/${category.category}/${product.id}`}>
									<h2>{product.name}</h2>
									<img
										src={`${process.env.REACT_APP_API_URL}/api-candyshop/${product.image_path}`}
										alt={`${category.category} ${product.name}`}
										className="category-image"
									/>
								</NavLink>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}
