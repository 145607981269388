import React from "react";
import { useConfig } from "../../configContext";

export const SelectCandies: React.FC = () => {
	const {
		getBonbonsDisponibles,
		addBonbon,
		getCategoriesDisponibles,
		getChocolatsDisponibles,
		getKinder,
		getPopCorn,
		setCategory,
		category,
		bonbons,
		produit,
		clearBonbons,
	} = useConfig();

	const bonbonColors = {
		Framboise: "#52d5fb",
		Vanille: "#f8fae8",
		Pomme: "#44c963",
		Fraise: "#f52c73",
		Banane: "#f5ff53",
		Rose: "#fc73c0",
		Violette: "#721f80",
		Noire: "#000000",
		Orange: "#ffac53",
	} as Record<string, string>;

	const OptionsList: React.FC<{
		options: readonly string[];
		onSelect: (option: string) => void;
		produit: string | null;
		bonbonColors: Record<string, string>; // Ajoutez une prop pour les couleurs
	}> = ({ options, onSelect, produit }) => (
		<div className="options-list">
			{options.map((option) => (
				<button
					key={option}
					onClick={() => onSelect(option)}
					className={`option-btn ${bonbons.includes(option) ? "active" : ""}`}
					style={
						produit === "brochette" && bonbonColors[option]
							? { backgroundColor: bonbonColors[option], color: "#000" } // Couleur liée au bonbon
							: {}
					}
				>
					{option}
				</button>
			))}
		</div>
	);

	// Composant réutilisable pour afficher des boutons d'options

	const categories = getCategoriesDisponibles();
	const bonbonsDispo = getBonbonsDisponibles();
	const chocolats = getChocolatsDisponibles();
	const kinder = getKinder();
	const popCorn = getPopCorn();

	return (
		<div className="candies">
			<div>
				<h2>Étape 1 : Choisir une catégorie</h2>
				<div className="categories">
					{categories.map((cat) => (
						<button
							key={cat}
							onClick={() => {
								clearBonbons();
								setCategory(cat);
							}}
							className={`category-btn ${category === cat ? "active" : ""}`}
						>
							{cat}
						</button>
					))}
				</div>
			</div>
			{/* Affichage conditionnel */}
			{category === "" && <></>}
			{category === "kinder" && (
				<div>
					<h2>Étape 2 : Choisir l'assortiment de kinder</h2>
					<OptionsList
						options={kinder}
						onSelect={addBonbon}
						produit={produit}
						bonbonColors={bonbonColors}
					/>
				</div>
			)}
			{category === "popCorn" && (
				<div>
					<h2>Étape 2 : Choisir l'assortiment de pop-corn</h2>
					<OptionsList
						options={popCorn}
						onSelect={addBonbon}
						produit={produit}
						bonbonColors={bonbonColors}
					/>
				</div>
			)}

			{category === "chocolat" && (
				<div>
					<h2>Étape 2 : Choisir des chocolats (3 choix max) </h2>
					<OptionsList
						options={chocolats}
						onSelect={addBonbon}
						produit={produit}
						bonbonColors={bonbonColors}
					/>
				</div>
			)}

			{category === "bonbons" && (
				<div>
					<h2>Étape 2 : Choisir des bonbons (3 choix max)</h2>
					<OptionsList
						options={bonbonsDispo}
						onSelect={addBonbon}
						produit={produit}
						bonbonColors={bonbonColors}
					/>
				</div>
			)}
		</div>
	);
};
