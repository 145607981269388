import React, { useState } from "react";
import { useConfig } from "../../configContext";

type ProduitType = "cône" | "brochette" | "sachet" | "cup";

export const SelectProduct: React.FC = () => {
  const { setProduit } = useConfig();

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);

  const handleSelect = (produit: ProduitType) => {
    setProduit(produit);
    setSelectedProduct(produit);
  };

  return (
    <div className="product">
      <h2>Étape 1 : Choisir un produit</h2>
      <div className="product-btn">
      <button className={`productBtn cone ${selectedProduct === "cône" ? "selected" : ""}`} onClick={() => handleSelect("cône")}></button>
      <button className={`productBtn brochette ${selectedProduct === "brochette" ? "selected" : ""}`} onClick={() => handleSelect("brochette")}></button>
      <button className={`productBtn sachet ${selectedProduct === "sachet" ? "selected" : ""}`} onClick={() => handleSelect("sachet")}></button>
      <button className={`productBtn cup ${selectedProduct === "cup" ? "selected" : ""}`} onClick={() => handleSelect("cup")}></button>
      </div>
    </div>
  );
};
