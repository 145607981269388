import React, { StrictMode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { Footer } from "./components/footer/footer";
import { Header } from "./components/header/header";
import { CategoriesPage } from "./pages/categoriesPage/categoriesPage";
import { HomePage } from "./pages/homePage/homePage";
import { Product } from "./pages/productPage/productPage";
import { LoginPage } from "./pages/loginPage/loginPage";
import { UserPage } from "./pages/userPage/userPage";
import { GalleriePhotos } from "./pages/gallerie-potos/galleriePhotos";
import { Configurator } from "./pages/configurator/Configurator";
import { Commande } from "./pages/commande/commande";
import { ConfigProvider } from "./configContext";
import { Commandes } from "./pages/orders/orders";
import { CommandeRecap } from "./pages/orderRecap/orderRecap";
import { ErrorPage } from "./pages/errorPage/errorPage";
import {CookieConsent} from "./components/tarteaucitron/tarteaucitron";
import { Confid } from "./pages/confidentialite-cookie/confid";
import { Cookies } from "./pages/confidentialite-cookie/cookies";
import { Conditions } from "./pages/conditions/conditions";

function App() {
	return (
		<StrictMode>
			<ConfigProvider>
				<BrowserRouter>
					<CookieConsent />
					<Header />
					<Routes>
						<Route path="/404" element={<ErrorPage />} />
						<Route path="/conditions" element={<Conditions />} />
						<Route path="/" element={<HomePage />} />
						<Route path="/login" element={<LoginPage />} />
						<Route path="/user" element={<UserPage />} />
						<Route path="/gallerie-photos" element={<GalleriePhotos />} />
						<Route path="/:category" element={<CategoriesPage />} />
						<Route path="/:category/:id" element={<Product />} />
						<Route path="/configurateur" element={<Configurator />} />
						<Route path="/commande" element={<Commande />} />
						<Route path="/historique-commandes" element={<Commandes />} />
						<Route
							path="/historique-commandes/:id"
							element={<CommandeRecap />}
						/>
						<Route path="/politique-de-confidentialite" element={<Confid />} />
						<Route path="/politique-de-cookies" element={<Cookies />} />
					</Routes>
					<Footer />
				</BrowserRouter>
			</ConfigProvider>
		</StrictMode>
	);
}

export default App;
