import React from "react";

export function Confid() {
	return (
		<div>
			<h1>Politique de Confidentialité</h1>
			<p>
				Cette politique de confidentialité décrit comment nous collectons,
				utilisons et protégeons vos données personnelles.
			</p>
			<h2>1. Collecte des informations</h2>
			<p>
				Nous collectons certaines informations lorsque vous visitez notre site,
				telles que votre adresse IP, le type de navigateur, et d'autres données
				techniques.
			</p>
			<h2>2. Utilisation des informations</h2>
			<p>
				Les informations collectées sont utilisées pour améliorer l'expérience
				de l'utilisateur et à des fins d'analyse via des outils comme Google
				Analytics.
			</p>
			<h2>3. Sécurisation des données</h2>
			<p>
				Nous prenons toutes les mesures nécessaires pour protéger vos données
				personnelles. Nous ne partageons pas vos informations avec des tiers
				sans votre consentement.
			</p>
			<h2>4. Vos droits</h2>
			<p>
				Vous avez le droit d'accéder à vos données, de les rectifier ou de les
				supprimer. Vous pouvez également vous opposer au traitement de vos
				données dans certaines circonstances.
			</p>
			<p>
				Si vous avez des questions ou souhaitez exercer vos droits,
				contactez-nous via{" "}
				<a href="mailto:candyshopbystess@outlook.fr">candyshopbystess@outlook.fr</a>.
			</p>
		</div>
	);
}
