import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { RootState } from "../../redux/actions/typeAction";
import "./orderRecap.scss";

interface Order {
	id: number;
	created_at: string;
	status: string;
	ports_amount: number;
	total_amount: number;
	stripe_transaction_id: string;
	tracking_number: string;
	address: string;
	items: itemModel[];
}
interface itemModel {
	name: string;
	quantity: number;
	price: number;
	image: string;
}

export function CommandeRecap() {
	const params = useParams();
	const id = params.id;
	const token = useSelector((state: RootState) => state.token.token);
	const [order, setOrder] = useState<Order>({
		id: 0,
		created_at: "",
		status: "",
		ports_amount: 0,
		total_amount: 0,
		stripe_transaction_id: "",
		tracking_number: "",
		address: "",
		items: [],
	});

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/orders/${id}`, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setOrder(data.body);
			})
			.catch((error) => console.error(error));
	}, [id, token]);

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	return (
		<>
			<Helmet>
				<title>visualisez votre commande sur candyshop by stess</title>
				<meta
					name="description"
					content={`visualisez votre commande n°${id} sur candyshop by stess`}
				/>
				<meta name="robots" content="noindex, nofollow" />
				<link
					rel="canonical"
					href={`https://candyshop-by-stess.fr/historique-commandes/${id}`}
				/>
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			</Helmet>
			<NavLink
				to={`/historique-commandes/`}
				className="homePage-link-back"
				aria-label="Retour à la page historique des commandes"
			>
				<i className="fa-solid fa-arrow-left" aria-hidden="true"></i>
				Retour
			</NavLink>
			<div className="orderRecap page">
				<h1>Commande Recapitulatif</h1>
				<div className="orderRecap-container">
					<div className="orderRecap-items">
						<h3>Produits</h3>
						<ul>
							{order.items.map((item, index) => (
								<li key={index}>
									<img
										src={`${process.env.REACT_APP_API_URL}/api-candyshop/${item.image}`}
										alt={item.name}
									/>
									<div className="item-infos">
										<p>
											{" "}
											<strong>nom du produit:</strong> {item.name}
										</p>
										<p>
											{" "}
											<strong>quantité:</strong> {item.quantity}
										</p>
										<p>
											{" "}
											<strong>prix unitaire:</strong> {item.price.toFixed(2)}€
										</p>
										<p>
											{" "}
											<strong>prix total:</strong>{" "}
											{(item.price * item.quantity).toFixed(2)}€
										</p>
									</div>
								</li>
							))}
						</ul>
					</div>
					<div className="orderRecap-infos">
						<h2>Commande N° {order.id}</h2>
						<p>
							{" "}
							<strong>date de commande:</strong>
							{order.created_at}
						</p>
						<p>
							{" "}
							<strong>statut:</strong>
							{order.status}
						</p>
						<p>
							{" "}
							<strong>numéro de suivi:</strong>{" "}
							{order.tracking_number || "numéro de suivi non renseigné"}
						</p>
						<p>
							{" "}
							<strong>prix de la commande:</strong>{" "}
							{((order.total_amount - order.ports_amount) / 100).toFixed(2)} €
						</p>
						<p>
							{" "}
							<strong>fraits de ports:</strong>{" "}
							{(order.ports_amount / 100).toFixed(2)} €
						</p>
						<p>
							{" "}
							<strong>prix total:</strong>{" "}
							{(order.total_amount / 100).toFixed(2)} €
						</p>
						<p>
							{" "}
							<strong>numéro de transaction:</strong>{" "}
							{order.stripe_transaction_id}
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
