import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import boiteImage425 from "../../assets/boite-grande-homePage-2-425.png";
import boiteImage768 from "../../assets/boite-grande-homePage-2-768.png";
import boiteImage1024 from "../../assets/boite-grande-homePage-2-1024.png";
import boiteImage1440 from "../../assets/boite-grande-homePage-2-1440.png";
import configurateurImage425 from "../../assets/configurateur-425.png";
import configurateurImage768 from "../../assets/configurateur-768.png";
import configurateurImage1024 from "../../assets/configurateur-1024.png";
import configurateurImage1440 from "../../assets/configurateur-1440.png";
import chocolatImage425 from "../../assets/chocolats-425.png";
import chocolatImage768 from "../../assets/chocolats-768.png";
import chocolatImage1024 from "../../assets/chocolats-1024.png";
import chocolatImage1440 from "../../assets/chocolats-1440.png";
import gateauImage425 from "../../assets/gateau-kinder-3-425.png";
import gateauImage768 from "../../assets/gateau-kinder-3-768.png";
import gateauImage1024 from "../../assets/gateau-kinder-3-1024.png";
import gateauImage1440 from "../../assets/gateau-kinder-3-1440.png";
import preparationsImage425 from "../../assets/prepa-425.png";
import preparationsImage768 from "../../assets/prepa-768.png";
import preparationsImage1024 from "../../assets/prepa-1024.png";
import preparationsImage1440 from "../../assets/prepa-1440.png";
import sucreImage425 from "../../assets/sucre-425.png";
import sucreImage768 from "../../assets/sucre-768.png";
import sucreImage1024 from "../../assets/sucre-1024.png";
import sucreImage1440 from "../../assets/sucre-1440.png";
import vracImage425 from "../../assets/vrac-425.png";
import vracImage768 from "../../assets/vrac-768.png";
import vracImage1024 from "../../assets/vrac-1024.png";
import vracImage1440 from "../../assets/vrac-1440.png";
import veganImage425 from "../../assets/vegan-425.png";
import veganImage768 from "../../assets/vegan-768.png";
import veganImage1024 from "../../assets/vegan-1024.png";
import veganImage1440 from "../../assets/vegan-1440.png";
import { Cart } from "../../components/cart/cart";
import "./homePage.scss";

interface Products {
	description: string;
	id: number;
	image_path: string;
	name: string;
	poids: number;
	price: number;
	tags: string[];
}

export function HomePage() {
	const [allPhotos, setAllPhotos] = useState<string[]>([]);
	const [photo, setPhoto] = useState<string>("");
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const allPhotosShuffeled = allPhotos.sort(() => Math.random() - 0.5);
	const width = window.innerWidth;

	useEffect(() => {
		// Récupérer les produits depuis l'API
		fetch(`${process.env.REACT_APP_API_URL}/api-candyshop/products`)
			.then((response) => response.json())
			.then((data) => {
				const imagePaths = data.map((product: Products) => product.image_path);
				setAllPhotos(imagePaths);
				if (imagePaths.length > 0) setPhoto(imagePaths[0]);
			});
	}, []);

	useEffect(() => {
		// Créer un carrousel uniquement lorsque `allPhotos` est défini
		if (allPhotosShuffeled.length > 0) {
			let i = 0;
			intervalRef.current = setInterval(() => {
				setPhoto(allPhotosShuffeled[i]);
				i = (i + 1) % allPhotosShuffeled.length; // Boucle circulaire
			}, 1500);

			// Nettoyer l'intervalle à la fin du cycle de vie
			return () => {
				if (intervalRef.current) {
					clearInterval(intervalRef.current);
				}
			};
		}
	}, [allPhotos, allPhotosShuffeled]);

	const structuredData = {
		"@context": "http://schema.org",
		"@type": "LocalBusiness",
		name: "Candyshop By Stess",
		image: "https://candyshop-by-stess.fr/assets/image-reseaux.png",
		telephone: "06 63 69 14 86",
		address: {
			"@type": "PostalAddress",
			streetAddress: "22 rue des hêtres",
			addressLocality: "Chasseneuil-du-Poitou",
			postalCode: "86360",
			addressCountry: "FR",
		},
		priceRange: "A partir de 2€",
		openingHoursSpecification: {
			"@type": "OpeningHoursSpecification",
			dayOfWeek: [
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			],
			opens: "09:00",
			closes: "19:00",
		},
		url: "https://candyshop-by-stess.fr",
		review: {
			"@type": "Review",
			author: { "@type": "Person", name: "sandrine LEBLOND" },
			datePublished: "2024-11-21",
			reviewBody:
				"Des créations absolument délicieuses, je recommande vivement !",
			reviewRating: {
				"@type": "Rating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "5",
			},
		},
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "4.9",
			reviewCount: "85",
		},
	};

	const itemList = {
		"@context": "http://schema.org",
		"@type": "ItemList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "gallerie photos",
				url: "https://candyshop-by-stess.fr/gallerie-photos",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "configurateur",
				url: "https://candyshop-by-stess.fr/configurateur",
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "gâteau",
				url: "https://candyshop-by-stess.fr/gateau",
			},
			{
				"@type": "ListItem",
				position: 4,
				name: "assortiment",
				url: "https://candyshop-by-stess.fr/assortiment",
			},
			{
				"@type": "ListItem",
				position: 5,
				name: "chocolats",
				url: "https://candyshop-by-stess.fr/chocolats-Page",
			},
			{
				"@type": "ListItem",
				position: 6,
				name: "sucre",
				url: "https://candyshop-by-stess.fr/sucre",
			},
			{
				"@type": "ListItem",
				position: 7,
				name: "preparations",
				url: "https://candyshop-by-stess.fr/preparations",
			},
			{
				"@type": "ListItem",
				position: 8,
				name: "bonbon en vrac",
				url: "https://candyshop-by-stess.fr/vrac",
			},
			{
				"@type": "ListItem",
				position: 9,
				name: "séléction VEGAN",
				url: "https://candyshop-by-stess.fr/vegan",
			},
		],
	};

	return (
		<>
			<Helmet>
				<title>Candyshop By Stess</title>
				<meta
					name="description"
					content="Candyshop by Stess : créations sucrées artisanales. Découvrez cônes, gâteaux et assortiments gourmands faits maison. Commandez vos douceurs maintenant!"
				/>
				<link rel="canonical" href={`https://candyshop-by-stess.fr/`} />
				<script type="application/ld+json">
					{JSON.stringify([structuredData, itemList])}
				</script>
			</Helmet>
			<div className="homePage page">
				<Cart />
				<h1>CandyShop By Stess: créations sucrées artisanales</h1>
				<div className="homePage-wrapper">
					<div>
						<NavLink
							to="/gallerie-photos"
							className="homePage-wrapper-link gallerie-homePage"
							aria-labelledby="gallerie-title"
						>
							<img
								src={`${process.env.REACT_APP_API_URL}/api-candyshop/${photo}`}
								loading="lazy"
								alt="CandyShop By Stess: gallerie photos de nos produits."
							/>
							<h3 id="brochette-title">Gallerie photos</h3>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/configurateur"
							className="homePage-wrapper-link brochette-homePage"
							aria-labelledby="configurateur-title"
						>
							<img
								src={
									width <= 425
										? configurateurImage425
										: width > 425 && width <= 768
										? configurateurImage768
										: width > 768 && width <= 1024
										? configurateurImage1024
										: configurateurImage1440
								}
								alt="CandyShop By Stess: configurateur personalisation produits."
							/>
							<h3 id="brochette-title">Créez vos produits personnalisés</h3>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/gateau"
							className="homePage-wrapper-link cake-homePage"
							aria-labelledby="gateau-title"
						>
							<img
								src={
									width <= 425
										? gateauImage425
										: width > 425 && width <= 768
										? gateauImage768
										: width > 768 && width <= 1024
										? gateauImage1024
										: gateauImage1440
								}
								alt="CandyShop By Stess: Découvrez nos gâteaux Kinder faits maison."
							/>
							<h2 id="gateau-title">Les Gâteaux Kinder</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/assortiment"
							className="homePage-wrapper-link box-homePage"
							aria-labelledby="boite-title"
						>
							<img
								src={
									width <= 425
										? boiteImage425
										: width > 425 && width <= 768
										? boiteImage768
										: width > 768 && width <= 1024
										? boiteImage1024
										: boiteImage1440
								}
								alt="CandyShop By Stess: Assortiments gourmands de bonbons et chocolats."
							/>
							<h2 id="boite-title">Les Assortiments</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/chocolats-Page"
							className="homePage-wrapper-link chocolat-homePage"
							aria-labelledby="boite-title"
						>
							<img
								src={
									width <= 425
										? chocolatImage425
										: width > 425 && width <= 768
										? chocolatImage768
										: width > 768 && width <= 1024
										? chocolatImage1024
										: chocolatImage1440
								}
								alt="CandyShop By Stess: Découvrez nos chocolats faits maison."
							/>
							<h2 id="boite-title">Les Chocolats</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/sucre"
							className="homePage-wrapper-link sucre-homePage"
							aria-labelledby="sucre-title"
						>
							<img
								src={
									width <= 425
										? sucreImage425
										: width > 425 && width <= 768
										? sucreImage768
										: width > 768 && width <= 1024
										? sucreImage1024
										: sucreImage1440
								}
								alt="CandyShop By Stess: Découvrez nos sucres aromatisés."
							/>
							<h2 id="boite-title">Les Sucres aromatisés</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/preparations"
							className="homePage-wrapper-link preparations-homePage"
							aria-labelledby="preparations-title"
						>
							<img
								src={
									width <= 425
										? preparationsImage425
										: width > 425 && width <= 768
										? preparationsImage768
										: width > 768 && width <= 1024
										? preparationsImage1024
										: preparationsImage1440
								}
								alt="CandyShop By Stess: Découvrez nos preparations de gâteaux."
							/>
							<h2 id="boite-title">Les Préparations de gâteaux</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/vrac"
							className="homePage-wrapper-link vrac-homePage"
							aria-labelledby="vrac-title"
						>
							<img
								src={
									width <= 425
										? vracImage425
										: width > 425 && width <= 768
										? vracImage768
										: width > 768 && width <= 1024
										? vracImage1024
										: vracImage1440
								}
								alt="CandyShop By Stess: Découvrez nos bonbon en vrac."
							/>
							<h2 id="boite-title">Les Bonbons en Vrac</h2>
						</NavLink>
					</div>
					<div>
						<NavLink
							to="/vegan"
							className="homePage-wrapper-link vegan-homePage"
							aria-labelledby="vrac-title"
						>
							<img
								src={
									width <= 425
										? veganImage425
										: width > 425 && width <= 768
										? veganImage768
										: width > 768 && width <= 1024
										? veganImage1024
										: veganImage1440
								}
								alt="CandyShop By Stess: Découvrez nos bonbon en vrac."
							/>
							<h2 id="boite-title">Séléction Vegan</h2>
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
}
